import React from "react"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { H1 } from "../components/UI/typography"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { Container } from "react-grid-system"
import Breadcrumbs from "../components/breadcrumbs"

export default ({ data, pageContext }) => {
  const {
    wordpressPage: { title, content, featured_media },
  } = data
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <Container>
      <SEO title={title} />
      <Breadcrumbs crumbs={crumbs} label={title} />
      {featured_media ? (
        <ImageWrapper>
          <ImageOverlay />
          <FeaturedImage
            fixed={featured_media.localFile.childImageSharp.fixed}
            alt={featured_media.alt_text}
          />
          <Heading>{title}</Heading>
        </ImageWrapper>
      ) : (
        <H1>{title}</H1>
      )}
      <Content
        className="page-content"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </Container>
  )
}

const FeaturedImage = styled(props => <Img {...props} />)``
const ImageWrapper = styled.div`
  position: relative;
  width: max-content;
`
const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #6cbddf99;
`
const Heading = styled(H1)`
  position: absolute;
  top: 50%;
  transform: translateX(50%);
  z-index: 2;
`

export const query = graphql`
  query Pages($slug: String) {
    wordpressPage(slug: { eq: $slug }) {
      id
      title
      content
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fixed(width: 1100, height: 550, toFormat: WEBP) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

const Content = styled.div``
